import Img2023_1 from '../img/jury/img2023_1.jpg'
import Img2023_2 from '../img/jury/img2023_2.jpg'
import Img2023_3 from '../img/jury/img2023_3.jpg'
import Img2022_1 from '../img/jury/img2022_1.jpg'
import Img2022_2 from '../img/jury/img2022_2.jpg'
import Img2022_3 from '../img/jury/img2023_3.jpg'
import Img2021_1 from '../img/jury/img2021_1.jpg'
import Img2021_2 from '../img/jury/img2021_2.jpg'
import Img2021_3 from '../img/jury/img2021_4.jpg'
import Img2020_1 from '../img/jury/img2020_1.jpg'
import Img2020_2 from '../img/jury/img2020_2.jpg'

const data = [
    {
      year: 2023,
      items: [
        {
          name: 'Эллина Андреева',
          country: 'Россия',
          image: Img2023_1,
          text1: 'Основатель студии красоты и лицензированной бьюти школы Profis Territory Ellina Andreeva',
          text2: 'Дипломированный судья Международных чемпионатов',
          text3: 'Инструктор Международного уровня',
          text4: 'Многократный победитель Международных, Чемпионатов России и Европы',
          text5: 'Разработчик адаптированных программ по маникюру и педикюру',
          text6: 'Автор учебных пособий',
        },
        {
            name: 'Елена Савельева',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Сертифицированный судья',
          text2: 'Организатор международного чемпионатов «Nail Bridge» «Nail Battle»',
          text3: 'Руководитель конкурсной команды @EXPERT.CENTRE',
          text4: 'Основатель студии красоты «ES - beauty studio» УЦ «EXPERT.CENTRE»',
          text5: 'Многократный призер Российских и Международных Чемпионатов',
          text6: 'Член состава директоров Международной Европейской ассоциации IJUA',
        },
        {
            name: 'Ирина Прижилевская',
            country: 'Румыния',
          image: Img2023_3,
          text1: 'Профессиональный художник,',
          text2: 'Международный конкурсный тренер',
          text3: 'Создатель авторских программ и курсов по дизайну ногтей',
          text4: 'Судья международных чемпионатов',
          text5: 'Основатель онлайн школы "Filosofia Beauty" (Румыния)',
          text6: 'Преподаватель школы Eclissi (Италия)',
        },
      ],
    },
    {
        year: 2022,
        items: [
          {
            name: 'Екатерина Кормилицына',
            country: 'Россия',
            image: Img2022_1,
            text1: 'Международный судья',
            text2: 'Основатель школы ногтевого искусства «My Love Center»',
            text3: 'Владелец салона «My Love Salon"',
            text4: 'Тренер команды « My Love»',
            text5: 'Многократный призер международных и российских соревнований',
            text6: 'Победитель премии ЛУЧШИЙ ТРЕНЕР 2023 г., BEST PEOPLE AWARDS 2023 в номинации GURU NAIL INDYSTRY',
          },
          {
            name: 'Елена Филоненко',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Действующий мастер маникюра',
            text2: 'Инструктор по нескольким техникам аппаратного маникюра',
            text3: 'Мастер имеющий официальную аккредитацию в своем деле',
            text4: 'Судья офлайн чемпионатов: cmcacademy Devajan Санкт-петербург 2021г., Devajan Екатеринбург 2023г., Он-лайн AWARDS, Он-лайн VIVA, Он-лайн PremiumNailStar',
            text5: 'Владелица своей студии красоты',
            text6: 'Разработчик авторских техник маникюра',
          },
          {
            name: 'Светлана Рыковская',
            country: 'Беларусь',
            image: Img2022_3,
            text1: 'Руководитель и учредитель компании «Международного тренингового центра Лилии» ',
            text2: 'VIP мастер/инструктор - преподаватель',
            text3: 'Многократный призер Международных чемпионатов',
            text4: 'Наставник-тренер команды-призера',
            text5: 'Статус Лучший учебный центр « МТЦ Лилии» Брестской обл. и Бреста',
            text6: 'Международный судья',
          },
        ],
      },
      {
        year: 2021,
        items: [
          {
            name: 'Ирина Пиркулиева',
            country: 'Украина',
          image: Img2021_1,
          text1: 'Тренер и судья международной категории',
          text2: 'Учредитель и Судья International Nail Championship «Diamond Nail»',
          text3: 'Основатель и Генеральный директор Академии Ирины Пиркулиевой',
          text4: 'Дважды обладатель премии « Золотые руки Азербайджана»',
          text5: 'Правообладатель собственных брендов api by Irina Pirkulieva и IRIS PROFESSIONAL',
          text6: 'Многократный победитель и призёр Международных чемпионатов',
        },
          {
            name: 'Инна Лантратова',
            country: 'Россия',
            image: Img2021_2,
            text1: 'Директор компании «KorNail»',
            text2: 'Председатель и главный тренер по компетенции ногтевой сервис "Абилимпикс"',
            text3: 'Тренер Национальной сборной "Абилимпикс"',
            text4: 'Тренер сборной команды Expert Centre',
            text5: 'Победитель и призёр российских и международных конкурсов',
            text6: 'Организатор чемпионатов Nail Bridge и Nail Battle',
          },
          {
            name: 'ИНГА АВАНЕСЯН',
            country: 'УКРАИНА',
            image: Img2021_3,
            text1: 'Директор по маникюру и педикюру Международной сети салонов «PIED-DE-POULE»',
            text2: 'Член Международной Ассоциации Красоты и Ухода IABC (International Association of Beauty and Care) ',
            text3: 'Обладатель Патента на Полезную модель «Способ моделирования формы «Стилет» искусственного ногтя»',
            text4: 'Спикер Международных форумов в сфере ногтевого сервиса',
            text5: 'Судья Международных конкурсов в сфере ногтевого дизайна',
            text6: 'Победитель Международной премии «Олимп красоты» в номинации «Инновации в процедурах»',
          },
        ],
      },
      {
        year: 2020,
        items: [
          {
            name: 'Татьяна Андрончик',
            country: 'Беларусь',
            image: Img2020_1,
            text1: '15 лет в ногтевом сервисе и подологии',
            text2: 'Международный судья на Чемпионатах по педикюру и подологии',
            text3: 'НЛП- практик',
            text4: 'МАК- консультант',
            text5: 'Спикер конференций и форумов для подологов и мастеров',
            text6: 'Владелец онлайн школы и клуба AKS',
          },
          {
            name: 'Инна Лантратова',
            country: 'Россия',
            image: Img2021_2,
            text1: 'Директор компании «KorNail»',
            text2: 'Председатель и главный тренер по компетенции ногтевой сервис "Абилимпикс"',
            text3: 'Тренер Национальной сборной "Абилимпикс"',
            text4: 'Тренер сборной команды Expert Centre',
            text5: 'Победитель и призёр российских и международных конкурсов',
            text6: 'Организатор чемпионатов Nail Bridge и Nail Battle',
          },
          {
            name: 'Ирина Парицкая',
            country: 'Россия',
            image: Img2020_2,
            text1: 'Специалист по педикюру с медицинским образованием',
            text2: 'Методист аппаратной техники педикюра',
            text3: 'Ведущий Преподаватель немецкой школы педикюра в РФ',
            text4: 'Спикер конгрессов и конференций',
            text5: 'Сооснователь Союза профессионалов педикюра «Авангард»',
            text6: 'Главный судья чемпионатов по педикюру от союза парикмахеров и косметологов России',
          },
        ],
      },
      {
        year: 2019,
        items: [
          {
            name: 'Светлана Рыковская',
            country: 'Беларусь',
            image: Img2022_3,
            text1: 'Руководитель и учредитель компании «Международного тренингового центра Лилии» ',
            text2: 'VIP мастер/инструктор - преподаватель',
            text3: 'Многократный призер Международных чемпионатов',
            text4: 'Наставник-тренер команды-призера',
            text5: 'Статус Лучший учебный центр « МТЦ Лилии» Брестской обл. и Бреста',
            text6: 'Международный судья',
          },
          {
            name: 'Елена Филоненко',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Действующий мастер маникюра',
            text2: 'Инструктор по нескольким техникам аппаратного маникюра',
            text3: 'Мастер имеющий официальную аккредитацию в своем деле',
            text4: 'Судья офлайн чемпионатов: cmcacademy Devajan Санкт-петербург 2021г., Devajan Екатеринбург 2023г., Он-лайн AWARDS, Он-лайн VIVA, Он-лайн PremiumNailStar',
            text5: 'Владелица своей студии красоты',
            text6: 'Разработчик авторских техник маникюра',
          },
          {
            name: 'Ирина Пиркулиева',
            country: 'Украина',
          image: Img2021_1,
          text1: 'Тренер и судья международной категории',
          text2: 'Учредитель и Судья International Nail Championship «Diamond Nail»',
          text3: 'Основатель и Генеральный директор Академии Ирины Пиркулиевой',
          text4: 'Дважды обладатель премии « Золотые руки Азербайджана»',
          text5: 'Правообладатель собственных брендов api by Irina Pirkulieva и IRIS PROFESSIONAL',
          text6: 'Многократный победитель и призёр Международных чемпионатов',
          },
        ],
      },
  ];

  export default data;
