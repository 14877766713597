import Img2023_1 from '../img/jury/data2 img2023_1.jpg'
import Img2023_2 from '../img/jury/data2 img2023_2.jpg'
import Img2023_3 from '../img/jury/data2 img2023_3.jpg'
import Img2022_1 from '../img/jury/data2 img2022_1.jpg'
import Img2022_2 from '../img/jury/data2 img2022_2.jpg'
import Img2022_3 from '../img/jury/data2 img2023_3.jpg'
import Img2021_1 from '../img/jury/data2 img2021_1.jpg'
import Img2021_2 from '../img/jury/data2 img2021_2.jpg'

const data2 = [
    {
      year: 2023,
      items: [
        {
          name: 'Виктория Марчук',
          country: 'Россия',
          image: Img2023_1,
          text1: 'Визажист, мастер по прическам Международного класса',
          text2: 'Многократный чемпион, призер российских и международных соревнований по макияжу, боди-арту',
          text3: 'Визажист недели моды в Париже',
          text4: 'Визажист, участник и организатор международных проектов',
          text5: 'Официальный визажист, член жюри конкурсов Мисс, Миссис Псков',
          text6: 'Владелица собственной студии макияжа',
        },
        {
            name: 'Оксана Стасевич',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Тренер/Преподаватель - бровист',
          text2: 'Эксперт - судья премии Melagarna awards',
          text3: 'Многократный Международный чемпион по ламинированию и окрашиванию бровей',
          text4: 'Лауреат премии «Преподаватель NUMBER ONE авторских BROW курсов»',
          text5: 'Эксперт - судья премии Melagarna awards в сфере оформления бровей',
          text6: 'Лауреат международной премии «Beauty pro awards» в номинации ТОП10 «Brow Stylist»',
        },
        {
            name: 'Алена Соболева',
            country: 'Россия',
          image: Img2023_3,
          text1: 'Номинант премий Топ-50 мастеров перманентного макияжа премии DORE2020, TOP-100SOCHI permanent Make up award 2022',
          text2: 'Участник чемпионата VIVO business award в сфере lash 2020',
          text3: 'Номинант премии PROFI PERFORMANCE AWARD IV 2020',
          text4: 'Владелица успешной студии красоты',
          text5: 'Эксперт-судья международных чемпионатов в сфере lash',
          text6: 'Тренер Российской Академии перманентного макияжа 2022',
        },
      ],
    },
    {
        year: 2022,
        items: [
          {
            name: 'Александра Корсак',
            country: 'Россия',
            image: Img2022_1,
            text1: 'Владелец собственной студии по оформлению бровей KORSAK.BROWS',
            text2: 'Эксперт Бренда Lerato.cosmetic, flario',
            text3: 'Амбассадор Бренда lenalevi.cosmetics',
            text4: 'Чемпион и призер чемпионатов "Silvi_star_champ по ламинированию бровей"',
            text5: 'Лауреат премии TOP 10 BROWS EXPERT 2022 при поддержки министерства образования',
            text6: 'Призер в 5 сезоне от Насти Клюквы',
          },
          {
            name: 'Лера Степанова',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Эксперт по продвижению и развитию мастеров и тренеров в бьюти-сфер',
            text2: 'Наставник по продажам в блоге',
            text3: 'Организатор интенсива «ЯРКИЙ СЕРИАЛ В СТОРИС»',
            text4: 'Топ бизнес-тренер по Нижегородской области',
            text5: 'Победитель чемпионата MARICH по моделированию бровей в России',
            text6: 'Обладательница премий: ЛЕГЕНДА БЬЮТИ 2023, НАСТАВНИК НА МИЛЛИОН 2023 и других',
          },
          {
            name: 'Анна Попова',
            country: 'Россия',
            image: Img2022_3,
            text1: 'Технолог-эксперт компании ESTEL',
            text2: 'Эксперт экзаменационной комиссии по компетенции парикмахерское искусство',
            text3: 'Действующий мастер-колорист',
            text4: 'Судья международных соревнований по парикмахерскому искусству',
            text5: 'Организатор мастер классов по колорированию',
            text6: 'Владелица собственной студии макияжа',
          },
        ],
      },
      {
        year: 2021,
        items: [
          {
            name: 'Екатерина Дементьева',
            country: 'Украина',
          image: Img2021_1,
          text1: 'Обучение парикмахерскому искусству в Соединенных Штатах Америки',
          text2: 'Победитель и призер региональных и международных чемпионатов',
          text3: '2015 год - вошла в пятерку лучших мастеров Европы, Чемпионат Европы FESTIVAL INTERNAZIONALE ESTETICA (Италия)',
          text4: 'Организатор семинаров, мастер-классов, курсов повышения квалификации',
          text5: 'Сооснователь салона красоты «Честно к себе»',
          text6: 'Владелица собственной студии макияжа',
        },
          {
            name: 'Екатерина Шусть',
            country: 'Россия',
            image: Img2021_2,
            text1: 'Судья, тренер и партнер Чемпионата Beauty Olymp',
            text2: 'Победитель Премии Top 10 brow expert 2022 при поддержке министерства образования РФ',
            text3: 'Тренер бровистов с правом присваивать квалификацию «Мастер-бровист» с выдачей госдиплома установленного образца',
            text4: 'Судья международного онлайн-чемпионата Brilliance 5 сезона',
            text5: 'Партнер школы Flario (г. Москва)',
            text6: 'Серебро в бровях в Brilliance 3 сезона',
          },
          {
            name: 'Оксана Стасевич',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Тренер/Преподаватель - бровист',
          text2: 'Эксперт - судья премии Melagarna awards',
          text3: 'Многократный Международный чемпион по ламинированию и окрашиванию бровей',
          text4: 'Лауреат премии «Преподаватель NUMBER ONE авторских BROW курсов»',
          text5: 'Эксперт - судья премии Melagarna awards в сфере оформления бровей',
          text6: 'Лауреат международной премии «Beauty pro awards» в номинации ТОП10 «Brow Stylist»',
          },
        ],
      },
      {
        year: 2020,
        items: [
          {
            name: 'Александра Корсак',
            country: 'Россия',
            image: Img2022_1,
            text1: 'Владелец собственной студии по оформлению бровей KORSAK.BROWS',
            text2: 'Эксперт Бренда Lerato.cosmetic, flario',
            text3: 'Амбассадор Бренда lenalevi.cosmetics',
            text4: 'Чемпион и призер чемпионатов "Silvi_star_champ по ламинированию бровей"',
            text5: 'Лауреат премии TOP 10 BROWS EXPERT 2022 при поддержки министерства образования',
            text6: 'Призер в 5 сезоне от Насти Клюквы',
          },
          {
            name: 'Алена Соболева',
            country: 'Россия',
          image: Img2023_3,
          text1: 'Номинант премий Топ-50 мастеров перманентного макияжа премии DORE2020, TOP-100SOCHI permanent Make up award 2022',
          text2: 'Участник чемпионата VIVO business award в сфере lash 2020',
          text3: 'Номинант премии PROFI PERFORMANCE AWARD IV 2020',
          text4: 'Владелица успешной студии красоты',
          text5: 'Эксперт-судья международных чемпионатов в сфере lash',
          text6: 'Тренер Российской Академии перманентного макияжа 2022',
          },
          {
            name: 'Виктория Марчук',
            country: 'Россия',
            image: Img2023_1,
            text1: 'Визажист, мастер по прическам Международного класса',
            text2: 'Многократный чемпион, призер российских и международных соревнований по макияжу, боди-арту',
            text3: 'Визажист недели моды в Париже',
            text4: 'Визажист, участник и организатор международных проектов',
            text5: 'Официальный визажист, член жюри конкурсов Мисс, Миссис Псков',
            text6: 'Владелица собственной студии макияжа',
          },
        ],
      },
      {
        year: 2019,
        items: [
          {
            name: 'Екатерина Шусть',
            country: 'Россия',
            image: Img2021_2,
            text1: 'Судья, тренер и партнер Чемпионата Beauty Olymp',
            text2: 'Победитель Премии Top 10 brow expert 2022 при поддержке министерства образования РФ',
            text3: 'Тренер бровистов с правом присваивать квалификацию «Мастер-бровист» с выдачей госдиплома установленного образца',
            text4: 'Судья международного онлайн-чемпионата Brilliance 5 сезона',
            text5: 'Партнер школы Flario (г. Москва)',
            text6: 'Серебро в бровях в Brilliance 3 сезона',
          },
          {
            name: 'Алена Соболева',
            country: 'Россия',
          image: Img2023_3,
          text1: 'Номинант премий Топ-50 мастеров перманентного макияжа премии DORE2020, TOP-100SOCHI permanent Make up award 2022',
          text2: 'Участник чемпионата VIVO business award в сфере lash 2020',
          text3: 'Номинант премии PROFI PERFORMANCE AWARD IV 2020',
          text4: 'Владелица успешной студии красоты',
          text5: 'Эксперт-судья международных чемпионатов в сфере lash',
          text6: 'Тренер Российской Академии перманентного макияжа 2022',
          },
          {
            name: 'Лера Степанова',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Эксперт по продвижению и развитию мастеров и тренеров в бьюти-сфер',
            text2: 'Наставник по продажам в блоге',
            text3: 'Организатор интенсива «ЯРКИЙ СЕРИАЛ В СТОРИС»',
            text4: 'Топ бизнес-тренер по Нижегородской области',
            text5: 'Победитель чемпионата MARICH по моделированию бровей в России',
            text6: 'Обладательница премий: ЛЕГЕНДА БЬЮТИ 2023, НАСТАВНИК НА МИЛЛИОН 2023 и других',
          },
        ],
      },
  ];

  export default data2;
