import React from 'react';


function CriteriaRating() {
  return (
    <div>
    <section className='rating-section'>
    <div className='wrapper'>
    <div className='title mb-100'>Критерии оценки "Звезды красоты"</div>
    <p className='sub-title'>Маникюр, моделирование, дизайн, педикюр и подология:</p>
        <ul className="custom-list mb-100">
        <li>Техническое мастерство: Оценивается точность выполнения маникюра и педикюра, качество моделирования и дизайна.</li>
        <li>Креативность: Инновационные и уникальные дизайны, способность выходить за рамки стандартов.</li>
        <li>Гигиена и безопасность: Строгое соблюдение стандартов гигиены и безопасности в работе.</li>
        <li>Эстетика: Общее впечатление от визуального исполнения и гармония цвета и формы.</li>
        </ul>
        <p className='sub-title'>Перманентный макияж, а также Lash и Brow направление:</p>
        <ul className="custom-list mb-100">
        <li>Мастерство: Высокий уровень профессионализма и точности в техниках нанесения макияжа и моделирования.</li>
        <li>Инновации: Применение новейших технологий и методик.</li>
        <li>Гармония образа: Способность создать целостный и подходящий образ, подчеркивающий индивидуальные черты клиента.</li>
        <li>Долговечность и практичность: Устойчивость результатов к воздействиям окружающей среды.</li>
        </ul>
        <p className='sub-title'>Парикмахерское искусство:</p>
        <ul className="custom-list mb-100">
        <li>Техника стрижки и укладки: Оценивается техническая исполнительность и инновационные подходы в стрижке и укладке.</li>
        <li>Креативность в колористике: Способность мастера работать с цветом, создавая уникальные и гармоничные сочетания.</li>
        <li>Финиш и стиль: Общее качество и завершенность прически.</li>
        <li>Здоровье волос: Уход и поддержание здоровья волос в процессе стилизации.</li>
        </ul>
        <p className='sub-title'>Инновации в косметологии:</p>
        <ul className="custom-list mb-100">
        <li>Новаторство: Введение новых продуктов, техник или технологий в косметологии.</li>
        <li>Эффективность: Доказательства клинической эффективности и пользы инноваций для кожи и тела.</li>
        <li>Безопасность и этичность: Соблюдение высоких стандартов безопасности и этических норм.</li>
        <li>Устойчивость и экологичность: Вклад в устойчивое развитие и минимизацию воздействия на окружающую среду.</li>
        </ul>
        <p className='sub-title'>Искусство Визажа:</p>
        <ul className="custom-list mb-100">
        <li>Креативность и уникальность: Оригинальные макияжи, подчеркивающие индивидуальность клиента и отражающие модные тренды.</li>
        <li>Техническое мастерство: Владение классическими и новыми техниками, работа с разными типами кожи и чертами лица.</li>
        <li>Качество и долговечность: Профессиональные средства и методы, обеспечивающие стойкий результат.</li>
        <li>Образовательный вклад: Обучение мастеров через мастер-классы и семинары, способствующие развитию индустрии.</li>
        </ul>
        <p className='sub-title'>Массаж и телесные терапии:</p>
        <ul className="custom-list mb-100">
        <li>Техника исполнения: Мастерство и глубина знаний в различных методиках массажа.</li>
        <li>Эффективность терапии: Оценка результатов и улучшений состояния клиента.</li>
        <li>Индивидуальный подход: Способность адаптировать техники к потребностям конкретного клиента.</li>
        <li>Атмосфера и комфорт: Создание расслабляющей и терапевтической атмосферы.</li>
        </ul>
        <p className='sub-title'>Мастерство в области стиля и имиджа</p>
        <ul className="custom-list mb-100">
        <li>Оригинальность и инновации: Создание уникальных, современных образов.</li>
        <li>Гармония и сочетаемость: Способность сочетать элементы одежды и аксессуаров для создания гармоничного образа.</li>
        <li>Понимание текущих трендов: Знание и применение последних трендов в моде.</li>
        <li>Персонализация: Индивидуальный подход к клиенту, учет его желаний и особенностей фигуры.</li>
        </ul>
        <p className='sub-title'>Фотография и видеография в индустрии красоты:</p>
        <ul className="custom-list mb-100">
        <li>Техническое качество: Четкость, композиция и освещение снимков.</li>
        <li>Творческий подход: Оригинальность идеи и исполнения проекта.</li>
        <li>Способность передать атмосферу: Умение зафиксировать и передать эмоции и настроение события или проекта.</li>
        <li>Редактирование и постобработка: Качество и стиль обработки материалов.</li>
        </ul>
    </div>

    </section>
    </div>
  );
}

export default CriteriaRating;
