import React from 'react';


function AboutAward() {
  return (
    <div>
    <section className='main-section'>
          <div className='wrapper'>
    <div className='title'>О конкурсе</div>
    <div className='text mb-50'>Международный конкурс beauty мастеров "Звезды красоты" — это не просто конкурс, это мероприятие, где каждый участник — профессионал высочайшего уровня. Это платформа для тех, кто стремится подтвердить своё мастерство на международной арене, демонстрируя уникальные навыки и инновационные подходы в области красоты.</div>
    <div className='text mb-50'>Участие в Международном конкурсе beauty мастеров "Звезды красоты" — это знак качества и профессионализма, подтверждающий, что мастер является одним из лучших в своей области. Только топ-специалисты проходят тщательный отбор, чтобы бороться за звание лучшего на этом престижном международном событии. Каждый участник и каждая представленная работа символизируют инновации и высокие стандарты индустрии красоты.</div>
    <div className='text mb-50'>Международный конкурс beauty мастеров "Звезды красоты" проводится с 2019 года и занимает значимое место в мировом сообществе beauty индустрии. Он собирает профессионалов, которые не только соревнуются, но и обмениваются опытом, поднимая планку качества и вдохновляя друг друга на достижение новых высот. Наши участники и судьи — это сердце мероприятия, их умения и таланты делают Международный конкурс beauty мастеров "Звезды красоты" одним из самых ожидаемых и уважаемых событий в календаре индустрии красоты.</div>
    <div className='text mb-50'>Принять участие в Международном конкурсе beauty мастеров "Звезды красоты" — значит подтвердить своё место среди лучших мастеров мира, это шанс оставить свой след в истории красоты.</div>
    <div className='text mb-50'>Международный конкурс beauty мастеров "Звезды красоты" является одним из самых значимых событий в индустрии красоты и моды. Это мероприятие собирает ведущих профессионалов со всего мира, каждый из которых уникален в своей области — от визажистов и стилистов до мастеров перманентного макияжа и эстетической медицины. Участие в конкурсе — это не просто возможность показать свое мастерство, но и честь представлять свою страну на международной арене, а также шанс внести свой вклад в глобальные тенденции красоты.</div>
    <div className='text mb-50'>Каждый год Международный конкурс beauty мастеров "Звезды красоты" привлекает участников и судей, которые являются истинными профессионалами, новаторами и лидерами в своих сферах на международной арене. Их уникальные подходы и профессиональные достижения не только вдохновляют участников, но и способствуют развитию всей индустрии. Этот конкурс демонстрирует высочайший уровень мастерства и креативности, делая его местом, где зарождаются новые идеи и технологии в области красоты.</div>
    <div className='text mb-50'>Международный конкурс beauty мастеров "Звезды красоты" стал международной платформой для профессионального обмена и сетевого взаимодействия, предоставляя участникам уникальную возможность для личного и карьерного роста. Участие в этом конкурсе открывает двери к новым возможностям, расширяет горизонты и помогает устанавливать важные профессиональные и международные контакты.</div>
    <div className='text mb-50'>Мы гордимся тем, что Международный конкурс beauty мастеров "Звезды красоты" вносит значительный вклад в развитие профессионального сообщества, повышая стандарты и ожидания в индустрии красоты на международной арене. Каждый участник и судья конкурса уникален, является лидером в своей области, тем самым вносит неоценимый вклад в этот процесс, доказывая, что красота — это не только внешнее восприятие, но и постоянное стремление к совершенству.</div>
    <div className='text mb-50'>Присоединяйтесь к нам, чтобы стать частью этой уникальной истории успеха, которая каждый год пишется на Международном конкурсе beauty мастеров "Звездах красоты"!</div>
    </div>

    </section>
    </div>
  );
}

export default AboutAward;
