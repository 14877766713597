import Img2023_1 from '../img/jury/data8 img2023_1.webp'
import Img2023_2 from '../img/jury/data8 img2023_2.webp'
import Img2023_3 from '../img/jury/data7 img2023_3.png'
import Img2022_1 from '../img/jury/data8 img2022_1.webp'
import Img2022_2 from '../img/jury/data8 img2022_2.webp'
import Img2022_3 from '../img/jury/data7 img2022_3.png'
import Img2021_1 from '../img/jury/data8 img2021_1.webp'
import Img2021_2 from '../img/jury/data8 img2021_2.webp'
import Img2021_3 from '../img/jury/data7 img2021_3.png'
import Img2020_1 from '../img/jury/data8 img2020_1.jpg'
import Img2020_2 from '../img/jury/data8 img2020_2.webp'
import Img2020_3 from '../img/jury/data8 img2020_3.webp'

const data8 = [
    {
      year: 2023,
      items: [
        {
          name: 'Кирилл Брюховецкий',
          country: 'Украина',
          image: Img2023_1,
          text1: '10 лет профессионального опыта в парикмахерской индустрии.',
          text2: 'Победитель и призер престижных парикмахерских конкурсов',
          text3: 'TOP50 One Shot Hair Award 2022    ',
          text4: 'Спикер на конгрессах Beautydayprohair и MAINSTAGE',
          text5: 'Преподаватель методики стрижки Park by Osipchuk',
        },
        {
            name: 'Елена Ирлица',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Ведущий технолог бренда «Lebel» и «Estessimo»',
          text2: 'Спикер на конгрессе парикмахеров «Beauty day pro hair»',
          text3: 'Стаж работы стилистом-парикмахером — 18 лет',
          text4: 'Создатель базовых курсов по колористике и химической завивке в рамках компании «Lebel».',
          text5: 'Номинант конкурса «Золотой подиум» и «Колорист Wella» (2009).',
        },
        {
            name: 'Исаева Анастасия',
            country: 'Россия',
          image: Img2021_1,
          text1: 'Ведущий технолог бренда LebeL.',
          text2: 'Неоднократный участник и спикер конференций парикмахерского искусства в Японии',
          text3: 'Основатель spa движения в России.',
          text4: 'Методолог легендарных обучающих программ по японским ритуалам и массажам кожи головы.',
          text5: 'Арт директор салона красоты Соната.',
        },
      ],
    },
    {
        year: 2022,
        items: [
          {
            name: 'Леонид Романов',
            country: 'Украина»',
            image: Img2022_1,
            text1: 'Мастер знающий о волосах всё и даже больше',
            text2: 'Победитель и номинант международных конкурсов «Парикмахер года Украина»',
            text3: 'Основатель собственного бренда косметики для волос.',
            text4: 'Бизнесмен, основатель салонов красоты «Leonid Romanov Hair».',
            text5: 'Арт-директор «Ассоциации высокой парикмахерской моды Франции в России — Haute Coiffure Française»',
          },
          {
            name: 'Татьяна Васильева',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Стилист-модельер-парикмахер. Колорист «L’Oréal» (2008).',
            text2: 'Дипломант сборной Москвы на чемпионате по прическам 2013 г.',
            text3: 'Участник конкурса «TrendVision aword Wella professional-2016». Амбассадор «Sebastian» с 2020 года.',
            text4: 'Дипломант российских и зарубежных школ по парикмахерскому искусству',
            text5: 'Стилист-модельер-парикмахер. Колорист «L’Oréal», Амбассадор «Sebastian» с 2020 года',
          },
          {
            name: 'Елена Ирлица',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Ведущий технолог бренда «Lebel» и «Estessimo»',
          text2: 'Спикер на конгрессе парикмахеров «Beauty day pro hair»',
          text3: 'Стаж работы стилистом-парикмахером — 18 лет',
          text4: 'Создатель базовых курсов по колористике и химической завивке в рамках компании «Lebel».',
          text5: 'Номинант конкурса «Золотой подиум» и «Колорист Wella» (2009).',
        },
        ],
      },
      {
        year: 2021,
        items: [
          {
            name: 'Исаева Анастасия',
            country: 'Россия',
          image: Img2021_1,
          text1: 'Ведущий технолог бренда LebeL.',
          text2: 'Неоднократный участник и спикер конференций парикмахерского искусства в Японии',
          text3: 'Основатель spa движения в России.',
          text4: 'Методолог легендарных обучающих программ по японским ритуалам и массажам кожи головы.',
          text5: 'Арт директор салона красоты Соната.',
        },
          {
            name: 'Ильмира Кошимбетова',
            country: 'Украина',
            image: Img2021_2,
            text1: 'Основатель и идейный вдохновитель проекта Beauty Battle.',
            text2: 'Создатель Центра Здоровья и Красоты «Точка • МИРА»',
            text3: 'Стилист-колорист по волосам более 17 лет.',
            text4: 'Выпускница школ «Dessange», «L’Oréal», «Wella», «Lebel».',
            text5: 'Бизнес-вумен',
          },
          {
            name: 'Виктория Ногина',
            country: 'Россия',
            image: Img2020_1,
            text1: 'Стилист-коллорист, визажист более 20 лет. ',
            text2: 'Амбассадор брендов «Wella» и «Nioxin».',
            text3: 'Чемпион Красноярского края.',
            text4: 'Стилист 1 канала на проектах «Голос»',
            text5: 'Регулярный стилист звёзд.',
          },
        ],
      },
      {
        year: 2020,
        items: [
          {
            name: 'Виктория Ногина',
            country: 'Россия',
            image: Img2020_1,
            text1: 'Стилист-коллорист, визажист более 20 лет. ',
            text2: 'Амбассадор брендов «Wella» и «Nioxin».',
            text3: 'Чемпион Красноярского края.',
            text4: 'Стилист 1 канала на проектах «Голос»',
            text5: 'Регулярный стилист звёзд.',
          },
          {
            name: 'Петрова Мария',
            country: 'Россия',
            image: Img2020_2,
            text1: 'Сертифицированный тренер-технолог.',
            text2: 'Коуч в области колористики и сложных техник окрашивания.',
            text3: 'Серебряный призер международного конкурса по колористике среди азиатских стран (2019).',
            text4: 'Обладатель премии в области колористики «Новатор года» среди колористов-технологов бренда',
            text5: 'Автор статей в модных журналах таких как «Hairs How», «El Trends» и «In Style».',
          },
          {
            name: 'Анна Гращенкова',
            country: 'Россия',
            image: Img2020_3,
            text1: 'Ведущий стилист-парикмахер с опытом более 10 лет.',
            text2: 'Арт-директор и действующий мастер салона «Andrey Silchenko Hair Salon» ',
            text3: 'Креативный директор коллекций «Andrey Silchenko Hair Salon».',
            text4: 'Создатель авторских курсов по стрижкам.',
            text5: 'Финалист международной премии «One Shot Hair Awards 2023»',
          },
        ],
      },
      {
        year: 2019,
        items: [
            {
                name: 'Ильмира Кошимбетова',
                country: 'Украина',
                image: Img2021_2,
                text1: 'Основатель и идейный вдохновитель проекта Beauty Battle.',
                text2: 'Создатель Центра Здоровья и Красоты «Точка • МИРА»',
                text3: 'Стилист-колорист по волосам более 17 лет.',
                text4: 'Выпускница школ «Dessange», «L’Oréal», «Wella», «Lebel».',
                text5: 'Бизнес-вумен',
              },
              {
                name: 'Анна Гращенкова',
                country: 'Россия',
                image: Img2020_3,
                text1: 'Ведущий стилист-парикмахер с опытом более 10 лет.',
                text2: 'Арт-директор и действующий мастер салона «Andrey Silchenko Hair Salon» ',
                text3: 'Креативный директор коллекций «Andrey Silchenko Hair Salon».',
                text4: 'Создатель авторских курсов по стрижкам.',
                text5: 'Финалист международной премии «One Shot Hair Awards 2023»',
              },
              {
                name: 'Елена Ирлица',
                country: 'Россия',
              image: Img2023_2,
              text1: 'Ведущий технолог бренда «Lebel» и «Estessimo»',
              text2: 'Спикер на конгрессе парикмахеров «Beauty day pro hair»',
              text3: 'Стаж работы стилистом-парикмахером — 18 лет',
              text4: 'Создатель базовых курсов по колористике и химической завивке в рамках компании «Lebel».',
              text5: 'Номинант конкурса «Золотой подиум» и «Колорист Wella» (2009).',
            },
        ],
      },
  ];

  export default data8;
