import Img2023_1 from '../img/jury/data3 img2023_1.jpg'
import Img2023_2 from '../img/jury/data3 img2023_2.jpg'
import Img2023_3 from '../img/jury/data3 img2023_3.jpg'
import Img2022_1 from '../img/jury/data3 img2022_1.jpg'
import Img2022_2 from '../img/jury/data3 img2022_2.jpg'
import Img2022_3 from '../img/jury/data3 img2022_3.jpg'

const data3 = [
    {
      year: 2023,
      items: [
        {
          name: 'Владимир Сарбашев',
          country: 'Россия',
          image: Img2023_1,
          text1: 'Маэстро колористики',
          text2: 'Автор AirTouch, одной из самых востребованных техник окрашивания в мире',
          text3: 'Владелец бьюти-ателье Subrosa и академии парикмахерского искусства VS Academy',
          text4: 'Преподаватель, на мастер-классы которого собираются полные залы ',
          text5: 'Прошел путь от ассистента до колориста международного уровня',
          text6: 'Более 15 лет работал мастером-универсалом и техническим директором Toni&Guy',
        },
        {
            name: 'Алексей Осипчук',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Чемпион Санкт-Петербурга, чемпион Москвы, чемпион России по парикмахерскому искусству, двукратный Чемпион Европы, серебряный чемпион мира',
          text2: 'Автор методики стрижки PARK BY OSIPCHUK',
          text3: 'Креативный директор компании Davines',
          text4: 'Победитель международного конкурса Oneshot Hair Awards 2023',
          text5: 'Основатель студий PARK BY OSIPCHUK',
          text6: 'Более 10 лет стаж работы',
        },
        {
            name: 'Глеб Морозов',
            country: 'Украина',
          image: Img2023_3,
          text1: 'Креативный парикмахер',
          text2: 'Международный преподаватель по парикмахерскому искусству',
          text3: 'Приглашённый преподаватель Capera Academy (Неаполь)',
          text4: 'Международный барбер года по версии журнала Barber Evo (UK) 2021',
          text5: 'Многократный финалист One Shot Hair Awards (US). Многократный победитель российских парикмахерских конкурсов',
          text6: 'Автор образовательной системы по точным техникам стрижки «STRIGONOMETRY»',
        },
      ],
    },
    {
        year: 2022,
        items: [
          {
            name: 'Паша Павлов',
            country: 'Россия',
            image: Img2022_1,
            text1: 'Профессиональный фотограф преподаватель',
            text2: 'Основное направление - работа в парикмахерской индустрии. Самый популярный и востребованный фотограф по волосам',
            text3: '72х кратный победитель разнообразных парикмахерских премий.',
            text4: 'Официальный фотограф модных домов / журналов и многих стилистов',
            text5: 'Создатель и преподаватель курса по мобильной фотографии для парикмахеров',
            text6: 'Наставник по продажам',
          },
          {
            name: 'Юлия Ясникова',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Многократный призер и обладатель кубков Гран-При профессиональных конкурсов парикмахерского искусства в номинации «Мужские стрижки»',
            text2: 'Финалист премии Hair Dressing Awards',
            text3: 'Куратор отделения мужских стрижек Академии Парикмахерского Искусства «Долорес» 2004-2012г.',
            text4: 'Обучила более 20.000 парикмахеров со всего мира',
            text5: 'Неоднократная участница показов и презентаций: Jean-Paul Gaultier, Giorgio Armani, Kenzo, Etro и других fashion событий',
            text6: 'Творческий партнер японского бренда LebeL',
          },
          {
            name: 'Юлия Воронцова',
            country: 'Россия',
            image: Img2022_3,
            text1: 'Международный блонд эксперт. Рекордсмен КНИГИ РЕКОРДОВ ГИННЕССА',
            text2: 'Blonde Expert №1 в России Fashion TV Russia. Победитель Oneshot Hair Awards, номинация «Platimun Shot» (США, Вашингтон)',
            text3: 'Победитель Russian Hairdressing Awards, DIGITAL-категории «BEST LOOK» (Россия, Москва)',
            text4: 'Победитель ЧЕМПИОНАТА России',
            text5: 'Победитель «БИТВА САЛОНОВ»',
            text6: 'Владелица ACADEMY JV MOSCOW & KRASNOYARSK и международной школы Julia Vorontsova Москва',
          },
        ],
      },
      {
        year: 2021,
        items: [
          {
            name: 'Паша Павлов',
            country: 'Россия',
            image: Img2022_1,
            text1: 'Профессиональный фотограф преподаватель',
            text2: 'Основное направление - работа в парикмахерской индустрии. Самый популярный и востребованный фотограф по волосам',
            text3: '72х кратный победитель разнообразных парикмахерских премий.',
            text4: 'Официальный фотограф модных домов / журналов и многих стилистов',
            text5: 'Создатель и преподаватель курса по мобильной фотографии для парикмахеров',
            text6: 'Наставник по продажам',
        },
          {
            name: 'Алексей Осипчук',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Чемпион Санкт-Петербурга, чемпион Москвы, чемпион России по парикмахерскому искусству, двукратный Чемпион Европы, серебряный чемпион мира',
          text2: 'Автор методики стрижки PARK BY OSIPCHUK',
          text3: 'Креативный директор компании Davines',
          text4: 'Победитель международного конкурса Oneshot Hair Awards 2023',
          text5: 'Основатель студий PARK BY OSIPCHUK',
          text6: 'Более 10 лет стаж работы',
          },
          {
            name: 'Глеб Морозов',
            country: 'Украина',
          image: Img2023_3,
          text1: 'Креативный парикмахер',
          text2: 'Международный преподаватель по парикмахерскому искусству',
          text3: 'Приглашённый преподаватель Capera Academy (Неаполь)',
          text4: 'Международный барбер года по версии журнала Barber Evo (UK) 2021',
          text5: 'Многократный финалист One Shot Hair Awards (US). Многократный победитель российских парикмахерских конкурсов',
          text6: 'Автор образовательной системы по точным техникам стрижки «STRIGONOMETRY»',
          },
        ],
      },
      {
        year: 2020,
        items: [
          {
            name: 'Владимир Сарбашев',
            country: 'Россия',
            image: Img2023_1,
            text1: 'Маэстро колористики',
            text2: 'Автор AirTouch, одной из самых востребованных техник окрашивания в мире',
            text3: 'Владелец бьюти-ателье Subrosa и академии парикмахерского искусства VS Academy',
            text4: 'Преподаватель, на мастер-классы которого собираются полные залы ',
            text5: 'Прошел путь от ассистента до колориста международного уровня',
            text6: 'Более 15 лет работал мастером-универсалом и техническим директором Toni&Guy',
          },
          {
            name: 'Юлия Ясникова',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Многократный призер и обладатель кубков Гран-При профессиональных конкурсов парикмахерского искусства в номинации «Мужские стрижки»',
            text2: 'Финалист премии Hair Dressing Awards',
            text3: 'Куратор отделения мужских стрижек Академии Парикмахерского Искусства «Долорес» 2004-2012г.',
            text4: 'Обучила более 20.000 парикмахеров со всего мира',
            text5: 'Неоднократная участница показов и презентаций: Jean-Paul Gaultier, Giorgio Armani, Kenzo, Etro и других fashion событий',
            text6: 'Творческий партнер японского бренда LebeL',
          },
          {
            name: 'Юлия Воронцова',
            country: 'Россия',
            image: Img2022_3,
            text1: 'Международный блонд эксперт. Рекордсмен КНИГИ РЕКОРДОВ ГИННЕССА',
            text2: 'Blonde Expert №1 в России Fashion TV Russia. Победитель Oneshot Hair Awards, номинация «Platimun Shot» (США, Вашингтон)',
            text3: 'Победитель Russian Hairdressing Awards, DIGITAL-категории «BEST LOOK» (Россия, Москва)',
            text4: 'Победитель ЧЕМПИОНАТА России',
            text5: 'Победитель «БИТВА САЛОНОВ»',
            text6: 'Владелица ACADEMY JV MOSCOW & KRASNOYARSK и международной школы Julia Vorontsova Москва',
          },
        ],
      },
      {
        year: 2019,
        items: [
          {
            name: 'Юлия Воронцова',
            country: 'Россия',
            image: Img2022_3,
            text1: 'Международный блонд эксперт. Рекордсмен КНИГИ РЕКОРДОВ ГИННЕССА',
            text2: 'Blonde Expert №1 в России Fashion TV Russia. Победитель Oneshot Hair Awards, номинация «Platimun Shot» (США, Вашингтон)',
            text3: 'Победитель Russian Hairdressing Awards, DIGITAL-категории «BEST LOOK» (Россия, Москва)',
            text4: 'Победитель ЧЕМПИОНАТА России',
            text5: 'Победитель «БИТВА САЛОНОВ»',
            text6: 'Владелица ACADEMY JV MOSCOW & KRASNOYARSK и международной школы Julia Vorontsova Москва',
          },
          {
            name: 'Паша Павлов',
            country: 'Россия',
            image: Img2022_1,
            text1: 'Профессиональный фотограф преподаватель',
            text2: 'Основное направление - работа в парикмахерской индустрии. Самый популярный и востребованный фотограф по волосам',
            text3: '72х кратный победитель разнообразных парикмахерских премий.',
            text4: 'Официальный фотограф модных домов / журналов и многих стилистов',
            text5: 'Создатель и преподаватель курса по мобильной фотографии для парикмахеров',
            text6: 'Наставник по продажам',
          },
          {
            name: 'Алексей Осипчук',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Чемпион Санкт-Петербурга, чемпион Москвы, чемпион России по парикмахерскому искусству, двукратный Чемпион Европы, серебряный чемпион мира',
          text2: 'Автор методики стрижки PARK BY OSIPCHUK',
          text3: 'Креативный директор компании Davines',
          text4: 'Победитель международного конкурса Oneshot Hair Awards 2023',
          text5: 'Основатель студий PARK BY OSIPCHUK',
          text6: 'Более 10 лет стаж работы',
          },
        ],
      },
  ];

  export default data3;
