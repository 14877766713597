import Img2023_1 from '../img/jury/data6 img2023_1.jpg'
import Img2023_2 from '../img/jury/data6 img2023_2.webp'
import Img2023_3 from '../img/jury/data6 img2023_3.jpg'
import Img2022_1 from '../img/jury/data6 img2022_1.jpg'
import Img2022_2 from '../img/jury/data6 img2022_2.jpg'
import Img2022_3 from '../img/jury/data6 img2023_3.jpg'
import Img2021_1 from '../img/jury/data6 img2021_1.jpeg'
import Img2021_2 from '../img/jury/data6 img2021_2.jpg'

const data6 = [
    {
      year: 2023,
      items: [
        {
            name: 'Ильмира Расулова',
            country: 'Россия',
            image: Img2023_1,
            text1: 'Эксперт по коммуникациям',
            text2: 'Директор по развитию St. Petersburg Fashion Week',
            text3: 'Руководитель маркетинга и рекламы FFancy Magazine',
            text4: 'Соучредитель и коммерческий директор Fashion Consulting Group',
            text5: 'Руководитель отдела маркетинга ',
        },
        {
            name: 'Радмила Аброщикова',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Российский дизайнер',
          text2: 'Председатель комитета индустрии моды',
          text3: 'Вице-президент Ассоциации Текстильщиков России',
          text4: 'Представитель Национальной Федерации Моды',
          text5: 'Опыт работы более 10 лет',
        },
        {
            name: 'Мария Казак',
            country: 'Россия',
          image: Img2023_3,
          text1: 'Художник-модельер',
          text2: 'Член Союза дизайнеров России',
          text3: 'Генеральный продюсер Самарского губернского маркет-фестиваля «Территория моды. Сделано в России»',
          text4: 'Руководитель отдела маркетинга',
          text5: 'Опыт работы более 12 лет',
        },
      ],
    },
    {
        year: 2022,
        items: [
          {
            name: 'Евгений Сидякин',
            country: 'Украина',
            image: Img2022_1,
            text1: 'Редактор, аналитик моды',
            text2: 'Автор канала Front Fashion',
            text3: 'Дизайнер одежды',
            text4: 'Сооснователь магазина FRONT',
            text5: 'Опыт работы более 10 лет',
          },
          {
            name: 'Марина Дэмченко',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Создатель первого российского журнала о моде Fashion Collection',
            text2: 'Куратор направления ЭкоМода в проекте "Экософия"',
            text3: 'Президентской платформы "Россия – страна возможностей"',
            text4: 'Дизайнер одежды',
            text5: 'Опыт работы более 15 лет',
          },
          {
            name: 'Алиса Богатова',
            country: 'Украина',
            image: Img2022_3,
            text1: 'Генеральный продюсер недели моды',
            text2: 'Общественный деятель',
            text3: 'Член ассоциации профессионалов в области легкой промышленности и дизайна',
            text4: 'Практикующий модельер',
            text5: 'Опыт работы более 10 лет',
          },
        ],
      },
      {
        year: 2021,
        items: [
          {
            name: 'Александр Рымкевич',
            country: 'Украина',
          image: Img2021_1,
          text1: 'Аналитик моды',
          text2: 'Арт-директор рекламных кампаний и консультант',
          text3: 'Создатель корпоративных программ обучения',
          text4: 'Куратор дизайнерских программ выставки Collection Premiere',
          text5: 'Опыт работы более 15 лет',
        },
          {
            name: 'Мэган Виртанен',
            country: 'Россия',
            image: Img2021_2,
            text1: 'Историк моды, культуролог',
            text2: 'Автор книг «Советская мода. 1917-1991»',
            text3: 'Спикер арт-кластера «Таврида»',
            text4: 'Аналитик моды',
            text5: 'Многократный участник ТопСпаФеста',
          },
          {
            name: 'Ильмира Расулова',
            country: 'Россия',
            image: Img2023_1,
            text1: 'Эксперт по коммуникациям',
            text2: 'Директор по развитию St. Petersburg Fashion Week',
            text3: 'Руководитель маркетинга и рекламы FFancy Magazine',
            text4: 'Соучредитель и коммерческий директор Fashion Consulting Group',
            text5: 'Руководитель отдела маркетинга ',
          },
        ],
      },
      {
        year: 2020,
        items: [
          {
            name: 'Евгений Сидякин',
            country: 'Украина',
            image: Img2022_1,
            text1: 'Основатель и руководитель «Международной школы Миопластики"',
            text2: 'Lifestyle-эксперт, массажист, косметолог, спа-терапевт, health-коуч',
            text3: 'Специалист по реабилитации и физическому воспитанию',
            text4: 'Специалист по созданию и оптимизации технологических процессов на предприятиях спа',
            text5: 'Автор «Миопластики лица и тела»',
          },
          {
            name: 'Марина Дэмченко',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Создатель первого российского журнала о моде Fashion Collection',
            text2: 'Куратор направления ЭкоМода в проекте "Экософия"',
            text3: 'Президентской платформы "Россия – страна возможностей"',
            text4: 'Дизайнер одежды',
            text5: 'Опыт работы более 15 лет',
          },
          {
            name: 'Радмила Аброщикова',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Российский дизайнер',
          text2: 'Председатель комитета индустрии моды',
          text3: 'Вице-президент Ассоциации Текстильщиков России',
          text4: 'Представитель Национальной Федерации Моды',
          text5: 'Опыт работы более 10 лет',
          },
        ],
      },
      {
        year: 2019,
        items: [
          {
            name: 'Радмила Аброщикова',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Российский дизайнер',
          text2: 'Председатель комитета индустрии моды',
          text3: 'Вице-президент Ассоциации Текстильщиков России',
          text4: 'Представитель Национальной Федерации Моды',
          text5: 'Опыт работы более 10 лет',
          },
          {
            name: 'Александр Рымкевич',
            country: 'Украина',
          image: Img2021_1,
          text1: 'Аналитик моды',
          text2: 'Арт-директор рекламных кампаний и консультант',
          text3: 'Создатель корпоративных программ обучения',
          text4: 'Куратор дизайнерских программ выставки Collection Premiere',
          text5: 'Опыт работы более 15 лет',
          },
          {
            name: 'Мария Казак',
            country: 'Россия',
          image: Img2023_3,
          text1: 'Художник-модельер',
          text2: 'Член Союза дизайнеров России',
          text3: 'Генеральный продюсер Самарского губернского маркет-фестиваля «Территория моды. Сделано в России»',
          text4: 'Руководитель отдела маркетинга',
          text5: 'Опыт работы более 12 лет',
          },
        ],
      },
  ];

  export default data6;
