import React from 'react';


function Nominations() {
  return (
    <div>
    <section className='nomination-section'>
    <div className='wrapper'>
    <div className='title'>Номинации международного конкурса beauty мастеров "Звезды красоты"</div>
    <div className='text mb-30'>На нашем Международном конкурсе beauty мастеров "Звезды красоты" мы предоставляем уникальную платформу для демонстрации мастерства в различных областях индустрии красоты. Каждая номинация оценивается отдельно, и участники имеют возможность проявить свои лучшие навыки и креативность</div>
    </div>
    </section>
    <section class="for-section">
  <div class="for-content">
    <div className='for-text-start'>
    <div class="big-number">01</div>
    <div class="for-text">Маникюр, моделирование, дизайн, педикюр и подология</div>
    </div>
    <div className='for-text-end'>
    <div class="big-number">02</div>
    <div class="for-text">Перманентный макияж, а также Lash и Brow направление</div>
    </div>
    <div className='for-text-start'>
    <div class="big-number">03</div>
    <div class="for-text">Парикмахерское искусство</div>
    </div>
    <div className='for-text-end'>
    <div class="big-number">04</div>
    <div class="for-text">Инновации в косметологии</div>
    </div>
    <div className='for-text-start'>
    <div class="big-number">05</div>
    <div class="for-text">Искусство Визажа</div>
    </div>
    <div className='for-text-end'>
    <div class="big-number">06</div>
    <div class="for-text">Массаж и телесные терапии</div>
    </div>
    <div className='for-text-start'>
    <div class="big-number">07</div>
    <div class="for-text">Мастерство в области стиля и имиджа</div>
    </div>
    <div className='for-text-end'>
    <div class="big-number">08</div>
    <div class="for-text">Фотография и видеография в индустрии красоты</div>
    </div>
  </div>
</section>
<section className='main-section'>
    <div className='wrapper'>
    <div className='title'>Маникюр, моделирование, дизайн, педикюр и подология</div>
    <div className='text mb-100'>Эта номинация предназначена для выдающихся мастеров ногтевого сервиса и подологии, которые профессионально подходят к уходу за руками и ногами. Участники демонстрируют свои умения в маникюре, моделировании, дизайне ногтей, а также в педикюре и подологических процедурах. Ожидается высокое качество выполнения, креативность в дизайне и эффективность подходов к здоровью и красоте стоп. Жюри оценивает техническое мастерство, инновационные методы и общий визуальный результат работ, который должен сочетать в себе как эстетику, так и функциональность.</div>
    <div className='title'>Перманентный макияж, а также Lash и Brow направление</div>
    <div className='text mb-100'>Эта номинация объединяет международных специалистов в области перманентного макияжа, а также оформления бровей и ресниц. Участники должны продемонстрировать высочайший уровень мастерства и креативности в создании гармоничных и эстетически привлекательных образов. Основное внимание уделяется качеству выполнения, инновационным техникам, а также способности подчеркнуть и усилить естественную красоту клиента. Эта категория оценивает всесторонние навыки участников в создании безупречного и долговременного макияжа, а также аккуратного и выразительного оформления бровей и ресниц.</div>
    <div className='title'>Парикмахерское искусство</div>
    <div className='text mb-100'>Мастера в этой номинации показывают свои умения в стрижке, укладке и колористике. Оценка производится на основе технических навыков, оригинальности подходов и общего впечатления от созданных причесок.</div>
    <div className='title'>Инновации в косметологии</div>
    <div className='text mb-100'>Эта номинация ориентирована на новаторские технологии и продукты в области ухода за кожей и телом. Оцениваются новшества, которые преобразуют процедуры ухода и предлагают революционные решения для косметических нужд.</div>
    <div className='title'>Искусство Визажа</div>
    <div className='text mb-100'>Эта номинация предназначена для мастеров визажа, которые демонстрируют высочайший уровень мастерства в создании макияжа. Участники проявляют креативность и техничность в нанесении макияжа, создавая образы, подчеркивающие индивидуальность каждого клиента. Ожидается оригинальность в подходе, соответствие современным тенденциям, а также умение работать с различными типами кожи и чертами лица. Жюри оценивает техническое мастерство, гармонию образа и стойкость макияжа, акцентируя внимание на качестве, креативности и профессионализме участника.</div>
    <div className='title'>Массаж и телесные терапии</div>
    <div className='text mb-100'>Номинация для специалистов, показывающих искусство массажа и телесных терапий, включая техники, направленные на улучшение здоровья, внешнего вида и общего благосостояния клиентов.</div>
    <div className='title'>Мастерство в области стиля и имиджа</div>
    <div className='text mb-100'>Категория для стилистов и имиджмейкеров, демонстрирующих умение создавать гармоничные и стильные образы для различных событий и целей. Оцениваются выбор одежды, аксессуаров и общая концепция стиля.</div>
    <div className='title'>Фотография и видеография в индустрии красоты</div>
    <div className='text mb-100'>Номинация для фотографов и видеооператоров, специализирующихся на съемке в индустрии красоты. Важным является умение зафиксировать красоту момента и передать атмосферу событий и проектов в индустрии красоты.</div>
    <div className='text '>Каждая номинация представляет собой возможность для профессионалов не только показать свои лучшие работы, но и получить признание на международном уровне, а также продвинуться в своей карьере. Присоединяйтесь к нашему празднику таланта и мастерства, чтобы продемонстрировать свои навыки и вдохновиться новыми идеями в мире красоты! </div>
    </div>
    </section>
    </div>
  );
}

export default Nominations;
