import Img2023_1 from '../img/jury/data5 img2023_1.jpg'
import Img2023_2 from '../img/jury/data5 img2023_2.jpg'
import Img2023_3 from '../img/jury/data5 img2023_3.jpg'
import Img2022_1 from '../img/jury/data5 img2022_1.jpg'
import Img2022_2 from '../img/jury/data5 img2022_2.jpg'
import Img2022_3 from '../img/jury/data5 img2023_3.jpg'
import Img2021_1 from '../img/jury/data5 img2021_1.jpg'
import Img2021_2 from '../img/jury/data5 img2021_2.jpg'

const data5 = [
    {
      year: 2023,
      items: [
        {
            name: 'Михаил Еремушкин',
            country: 'Россия',
            image: Img2023_1,
            text1: 'Заведующий отделом, главный врач Лечебно-реабилитационного клинического центра «Юдино»',
            text2: 'Главный научный сотрудник',
            text3: 'Профессор кафедры физической терапии и медицинской реабилитации',
            text4: 'Врач по лечебной физкультуре, доктор медицинских наук, профессор',
            text5: 'Президент Национальной Федерации Массажистов',
        },
        {
            name: 'Радмила Аброщикова',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Преподаватель Екатеринбургского Техникума Отраслевых Технологий и Сервиса направление "ЭСТЕТИЧЕСКАЯ КОСМЕТОЛОГИЯ"',
          text2: 'Эксперт WORLDSKILLS RUSSIA компетенции "ЭСТЕТИЧЕСКАЯ КОСМЕТОЛОГИЯ"',
          text3: 'Руководитель и преподаватель ШКОЛА-СТУДИЯ "НИКА-УРАЛ"',
          text4: 'Представитель Национальной Федерации Массажистов',
          text5: 'Дипломированный преподаватель по стандартам WORLDSKILLS в учреждениях СПО',
        },
        {
            name: 'Александр Гончаров',
            country: 'Россия',
          image: Img2023_3,
          text1: 'Врач практикующий специалист в области массажных технологий с 1990 г.',
          text2: 'Ведущий тренер бренда THALION',
          text3: 'Преподаватель массажных техник',
          text4: 'Автор ряда эксклюзивных массажных методик для лица и тела',
          text5: 'Обучался у лучших специалистов в области массажа и СПА в России, Франции, Англии, США, Таиланде, Индонезии и Японии',
        },
      ],
    },
    {
        year: 2022,
        items: [
          {
            name: 'Евгений Сидякин',
            country: 'Украина',
            image: Img2022_1,
            text1: 'Основатель и руководитель «Международной школы Миопластики"',
            text2: 'Lifestyle-эксперт, массажист, косметолог, спа-терапевт, health-коуч',
            text3: 'Специалист по реабилитации и физическому воспитанию',
            text4: 'Специалист по созданию и оптимизации технологических процессов на предприятиях спа',
            text5: 'Автор «Миопластики лица и тела»',
          },
          {
            name: 'Евгений Литвиченко',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Директор и главный специалист Центра массажных практик «FlyHands»',
            text2: 'Руководитель школы массажа',
            text3: 'Эксперт Национальной Федерации Массажистов',
            text4: 'Врач практикующий специалист в области массажных технологий с 1990 г.',
            text5: 'Судья чемпионатов по массажу ЕКС НФМ',
          },
          {
            name: 'Михаил Левченко',
            country: 'Россия',
            image: Img2022_3,
            text1: 'Реабилитолог, Член Российской Лиги Массажистов и Косметологов',
            text2: 'Чемпион Мира по массажу версии «National Federation of Massage Therapist» категория «Спа»',
            text3: 'Чемпион VII Международного Чемпионата ЦПРМ категории «Массаж лица»',
            text4: 'Дипломированный преподаватель',
            text5: 'Судья чемпионатов по массажу ЕКС НФМ',
          },
        ],
      },
      {
        year: 2021,
        items: [
          {
            name: 'Александр Щепарев',
            country: 'Украина',
          image: Img2021_1,
          text1: 'Старший преподаватель Международной школы СПА',
          text2: 'Чемпион мира по wellness массажу по версии международной ассоциации массажистов',
          text3: 'Организатор чемпионатов',
          text4: 'Организатор и судья чемпионатов по массажу ЕКС НФМ',
          text5: 'Руководитель школы массажа',
        },
          {
            name: 'Марианна Антошел',
            country: 'Россия',
            image: Img2021_2,
            text1: 'Руководитель салона красоты, массажной студии и школы массажа',
            text2: 'Серебряный чемпион 5 и 6 Открытого чемпионата России по косметологии и массажу',
            text3: 'Золотой чемпион 8 открытого чемпионата России в Номинации «Уход за кожей лица и шеи»',
            text4: 'Судья 9 чемпионата России по косметологии и массажу',
            text5: 'Многократный участник ТопСпаФеста',
          },
          {
            name: 'Радмила Аброщикова',
            country: 'Россия',
          image: Img2023_2,
          text1: 'Преподаватель Екатеринбургского Техникума Отраслевых Технологий и Сервиса направление "ЭСТЕТИЧЕСКАЯ КОСМЕТОЛОГИЯ"',
          text2: 'Эксперт WORLDSKILLS RUSSIA компетенции "ЭСТЕТИЧЕСКАЯ КОСМЕТОЛОГИЯ"',
          text3: 'Руководитель и преподаватель ШКОЛА-СТУДИЯ "НИКА-УРАЛ"',
          text4: 'Представитель Национальной Федерации Массажистов',
          text5: 'Дипломированный преподаватель по стандартам WORLDSKILLS в учреждениях СПО',
          },
        ],
      },
      {
        year: 2020,
        items: [
          {
            name: 'Евгений Сидякин',
            country: 'Украина',
            image: Img2022_1,
            text1: 'Основатель и руководитель «Международной школы Миопластики"',
            text2: 'Lifestyle-эксперт, массажист, косметолог, спа-терапевт, health-коуч',
            text3: 'Специалист по реабилитации и физическому воспитанию',
            text4: 'Специалист по созданию и оптимизации технологических процессов на предприятиях спа',
            text5: 'Автор «Миопластики лица и тела»',
          },
          {
            name: 'Александр Гончаров',
            country: 'Россия',
          image: Img2023_3,
          text1: 'Врач практикующий специалист в области массажных технологий с 1990 г.',
          text2: 'Ведущий тренер бренда THALION',
          text3: 'Преподаватель массажных техник',
          text4: 'Автор ряда эксклюзивных массажных методик для лица и тела',
          text5: 'Обучался у лучших специалистов в области массажа и СПА в России, Франции, Англии, США, Таиланде, Индонезии и Японии',
          },
          {
            name: 'Михаил Еремушкин',
            country: 'Россия',
            image: Img2023_1,
            text1: 'Заведующий отделом, главный врач Лечебно-реабилитационного клинического центра «Юдино»',
            text2: 'Главный научный сотрудник',
            text3: 'Профессор кафедры физической терапии и медицинской реабилитации',
            text4: 'Врач по лечебной физкультуре, доктор медицинских наук, профессор',
            text5: 'Президент Национальной Федерации Массажистов',
          },
        ],
      },
      {
        year: 2019,
        items: [
          {
            name: 'Марианна Антошел',
            country: 'Россия',
            image: Img2021_2,
            text1: 'Руководитель салона красоты, массажной студии и школы массажа',
            text2: 'Серебряный чемпион 5 и 6 Открытого чемпионата России по косметологии и массажу',
            text3: 'Золотой чемпион 8 открытого чемпионата России в Номинации «Уход за кожей лица и шеи»',
            text4: 'Судья 9 чемпионата России по косметологии и массажу',
            text5: 'Многократный участник ТопСпаФеста',
          },
          {
            name: 'Александр Гончаров',
            country: 'Россия',
          image: Img2023_3,
          text1: 'Врач практикующий специалист в области массажных технологий с 1990 г.',
          text2: 'Ведущий тренер бренда THALION',
          text3: 'Преподаватель массажных техник',
          text4: 'Автор ряда эксклюзивных массажных методик для лица и тела',
          text5: 'Обучался у лучших специалистов в области массажа и СПА в России, Франции, Англии, США, Таиланде, Индонезии и Японии',
          },
          {
            name: 'Евгений Литвиченко',
            country: 'Россия',
            image: Img2022_2,
            text1: 'Директор и главный специалист Центра массажных практик «FlyHands»',
            text2: 'Руководитель школы массажа',
            text3: 'Эксперт Национальной Федерации Массажистов',
            text4: 'Врач практикующий специалист в области массажных технологий с 1990 г.',
            text5: 'Судья чемпионатов по массажу ЕКС НФМ',
          },
        ],
      },
  ];

  export default data5;
